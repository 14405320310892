import React from "react";

const TeamUp = () => {
    return (
        <div className="bg-gradient-to-r from-red-200 via-orange-000 to-yellow-50 w-full overflow-hidden">
            

            {/* Second Row */}
            <div className="flex flex-col md:flex-row justify-center items-center gap-6 md:gap-16 px-4 py-8">
                <div className="max-w-full md:max-w-[400px] text-left font-bold text-xl md:text-2xl">
                    <h2>Team up with Gamob <br /> <span className="text-orange-500"> Top 10% of Google Certified </span> <br />Publishing Partners</h2>
                </div>
                <div className="max-w-full md:max-w-[400px] text-left text-sm md:text-base leading-relaxed text-gray-700">
                    <p>
                        Gamob empowers over 10,000 publishers helping them deliver expert
                        solutions in Product and User Acquisition Consultancy, Revenue & Inventory Optimization, and Inventory Quality Assurance. Our mission is to boost publishers' and ad networks' success, ensuring quality, efficiency, and sustainable growth through trusted, innovative partnerships.
                    </p>
                </div>
            </div>

            {/* Third Row */}
            <div className="flex flex-wrap justify-center gap-8 p-5">
                <div className="flex-1"></div>
                <div className="text-left text-xl font-bold max-w-[350px] border-l-4 border-yellow-300 pl-6 mb-4">
                    <h3 className="text-4xl font-bold">23.5%</h3>
                    <p>Boost eCPM</p>
                </div>
                <div className="text-left text-xl font-bold max-w-[350px] border-l-4 border-yellow-300 pl-6 mb-4">
                    <h3 className="text-4xl font-bold">128.4%</h3>
                    <p>Increase Revenue</p>
                </div>
                <div className="text-left text-xl font-bold max-w-[350px] border-l-4 border-yellow-300 pl-6 mb-4">
                    <h3 className="text-4xl font-bold">8.5%</h3>
                    <p>Global CPR</p>
                </div>
                <div className="flex-1"></div>
            </div>
        </div>
    );
};

export default TeamUp;
