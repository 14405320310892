import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for routing
import AOS from "aos";
import "aos/dist/aos.css";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out" });
  }, []);

  return (
    <nav
      className="bg-gradient-to-r from-red-100 via-white-100 to-yellow-50 text-black"
      data-aos="fade-up"
    >
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        {/* Logo Section */}
        <div
          className="flex items-center space-x-3"
          data-aos="fade-down"
          data-aos-delay="200"
        >
          <img
            src="../Images/logo.png"
            alt="Website Logo"
            className="h-20 w-20"
          />
          <Link
            to="/"
            className="text-2xl font-serif font-bold tracking-wider text-black"
          >
            AdOptimiz
          </Link>
        </div>

        {/* Hamburger Menu */}
        <button
          className="lg:hidden text-3xl focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          data-aos="zoom-in"
        >
          &#9776; {/* Hamburger Icon */}
        </button>

        {/* Links */}
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } absolute z-10 lg:static top-16 lg:top-0 left-0 w-full lg:w-auto lg:flex lg:space-x-6 flex-col lg:flex-row space-y-4 lg:space-y-0 py-4 lg:py-0`}
          data-aos="fade-down"
          data-aos-delay="300"
        >
          <Link
            to="/"
            className="block text-center lg:text-left px-4 py-2 text-lg font-medium hover:bg-yellow-400 rounded-lg transition duration-300"
            data-aos="fade-down"
            data-aos-delay="400"
          >
            Home
          </Link>
          <Link
            to="/publishers"
            className="block text-center lg:text-left px-4 py-2 text-lg font-medium hover:bg-yellow-400 rounded-lg transition duration-300"
            data-aos="fade-down"
            data-aos-delay="500"
          >
            Publishers
          </Link>
          <Link
            to="/about"
            className="block text-center lg:text-left px-4 py-2 text-lg font-medium hover:bg-yellow-400 rounded-lg transition duration-300"
            data-aos="fade-down"
            data-aos-delay="600"
          >
            About
          </Link>
          <Link
            to="/contact"
            className="block text-center lg:text-left px-4 py-2 text-lg font-medium hover:bg-yellow-400 rounded-lg transition duration-300"
            data-aos="fade-down"
            data-aos-delay="700"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
