// import React from "react";

// const CoreComponent = () => {
//     return (
//         <div className="w-full py-12 px-6 bg-gradient-to-r from-red-200 via-orange-000 to-yellow-50">
//             {/* First Row */}
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
//                 {/* Tile with Text */}
//                 <div className="ml-[200px] relative flex justify-center items-center">
//                     {/* Gradient Background */}
//                     {/* <div className="absolute inset-0 bg-gradient-radial from-red-300/40 via-red-100/20 to-transparent rounded-full w-[300px] h-[300px] pointer-events-none -z-10"></div> */}

//                     {/* Tile Content */}
//                     <div className="relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-60">
//                         {/* Serial Number */}
//                         <span className="absolute top-2 left-2 text-orange-600 font-bold text-4xl">01</span>

//                         {/* Heading */}
//                         <h3 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">Transparency Unveiled</h3>

//                         {/* Paragraph */}
//                         <p className="text-gray-600 text-xs">
//                             Directly channeling revenue from 3rd parties, we offer crystal-clear transparency. Seamlessly integrate with AdMob SDK for swift ad placement.
//                         </p>
//                     </div>
//                 </div>


//                 {/* Text on the right */}
//                 <div className="flex flex-col justify-center items-center text-center md:items-start md:text-left space-y-4 w-[450px]">
//                     {/* Small Text */}
//                     <span className="text-sm font-medium text-orange-500">
//                         OUR CORE VALUE
//                     </span>

//                     {/* Heading */}
//                     <h3 className="text-2xl sm:text-3xl font-bold text-gray-800">
//                         Where Transparency Meets Transformation
//                     </h3>

//                     {/* Paragraph */}
//                     <p className="text-gray-600 text-sm sm:text-base md:text-lg">
//                         We emphasize transparency to foster trust, commitment to expedite processes for publishers' benefit, conscientiousness to ensure excellence, and flexibility to cater to diverse needs, all aimed at empowering publishers to maximize revenue potential effortlessly.
//                     </p>
//                 </div>

//             </div>

//             {/* Second Row */}
//             <div className="flex  mb-12">
//                 {/* Tile 1 */}
//                 <div className="ml-[200px] relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-60">
//                     <span className="absolute top-2 left-2 text-orange-600 font-bold text-4xl">02</span>
//                     <h3 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">Dedicated Commitment</h3>
//                     <p className="text-gray-600 text-sm">
//                         Experience the ease of electronic contract signing-fast, secure, and guaranteeing maximum benefits for publishers.
//                     </p>
//                 </div>
//                 {/* Tile 2 */}
//                 <div className="ml-[30px] relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-60">
//                     <span className="absolute top-2 left-2 text-orange-600 font-bold text-4xl">03</span>
//                     <h3 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">Reputation Built on Excellence</h3>
//                     <p className="text-gray-600 text-sm">
//                         Backed by MOG, a trailblazer in mobile advertising optimization with over 7 years of expertise and a network of 50,000+ publishers.
//                     </p>
//                 </div>
//             </div>

//             {/* Third Row */}
//             <div className="flex">
//                 {/* Tile 1 */}
//                 <div className="ml-[350px] relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-60">
//                     <span className="absolute top-2 left-2 text-orange-600 font-bold text-4xl">04</span>
//                     <h3 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">Supreme Flexibility</h3>
//                     <p className="text-gray-600">
//                         Description for the first tile in the third row.
//                     </p>
//                 </div>
//                 {/* Tile 2 */}
//                 <div className="ml-[30px] relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-60">
//                     <span className="absolute top-2 left-2 text-orange-600 font-bold text-4xl">05</span>
//                     <h3 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">Concius Care</h3>
//                     <p className="text-gray-600">
//                         Description for the second tile in the third row.
//                     </p>
//                 </div>
//             </div>
//         </div>

//     );
// };

// export default CoreComponent;

import React from "react";

const CoreComponent = () => {
    return (
        <div className="w-full py-12 px-4 sm:px-6 lg:px-12 bg-gradient-to-r from-red-200 via-orange-100 to-yellow-50">
            {/* First Row */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                {/* Tile with Text */}
                <div className="relative flex justify-center items-center mx-auto md:ml-[200px]">
                    {/* Tile Content */}
                    <div className="relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-[90%%] sm:w-60">
                        {/* Serial Number */}
                        <span className="absolute top-2 left-2 text-orange-600 font-bold text-3xl sm:text-4xl">01</span>

                        {/* Heading */}
                        <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                            Transparency Unveiled
                        </h3>

                        {/* Paragraph */}
                        <p className="text-gray-600 text-xs">
                            Directly channeling revenue from 3rd parties, we offer crystal-clear transparency. Seamlessly integrate with AdMob SDK for swift ad placement.
                        </p>
                    </div>
                </div>

                {/* Text on the right */}
                <div className="flex flex-col justify-center items-center text-center md:items-start md:text-left space-y-4 px-4 sm:px-0">
                    {/* Small Text */}
                    <span className="text-sm font-medium text-orange-500">
                        OUR CORE VALUE
                    </span>

                    {/* Heading */}
                    <h3 className="text-lg sm:text-2xl lg:text-3xl font-bold text-gray-800">
                        Where Transparency Meets Transformation
                    </h3>

                    {/* Paragraph */}
                    <p className="text-gray-600 text-sm sm:text-base md:text-lg">
                        We emphasize transparency to foster trust, commitment to expedite processes for publishers' benefit, conscientiousness to ensure excellence, and flexibility to cater to diverse needs, all aimed at empowering publishers to maximize revenue potential effortlessly.
                    </p>
                </div>
            </div>

            {/* Second Row */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex lg:justify-center gap-8 mb-12">
                {/* Tile 1 */}
                <div className="relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-[90%] sm:w-60 mx-auto">
                    <span className="absolute top-2 left-2 text-orange-600 font-bold text-3xl sm:text-4xl">02</span>
                    <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Dedicated Commitment
                    </h3>
                    <p className="text-gray-600 text-sm">
                        Experience the ease of electronic contract signing-fast, secure, and guaranteeing maximum benefits for publishers.
                    </p>
                </div>

                {/* Tile 2 */}
                <div className="relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-[90%] sm:w-60 mx-auto">
                    <span className="absolute top-2 left-2 text-orange-600 font-bold text-3xl sm:text-4xl">03</span>
                    <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Reputation Built on Excellence
                    </h3>
                    <p className="text-gray-600 text-sm">
                        Backed by MOG, a trailblazer in mobile advertising optimization with over 7 years of expertise and a network of 50,000+ publishers.
                    </p>
                </div>
            </div>

            {/* Third Row */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex lg:justify-center gap-8">
                {/* Tile 1 */}
                <div className="relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-[90%] sm:w-60 mx-auto">
                    <span className="absolute top-2 left-2 text-orange-600 font-bold text-3xl sm:text-4xl">04</span>
                    <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Supreme Flexibility
                    </h3>
                    <p className="text-gray-600 text-sm">
                        Description for the first tile in the third row.
                    </p>
                </div>

                {/* Tile 2 */}
                <div className="relative bg-white border-l-4 border-yellow-300 p-6 rounded-lg shadow-lg h-[250px] w-[90%] sm:w-60 mx-auto">
                    <span className="absolute top-2 left-2 text-orange-600 font-bold text-3xl sm:text-4xl">05</span>
                    <h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Conscientious Care
                    </h3>
                    <p className="text-gray-600 text-sm">
                        Description for the second tile in the third row.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CoreComponent;
