import React from "react";

const Dedication = ({ heading, text }) => {
    return (
        <div className="w-full py-8 px-4 sm:px-6 lg:px-12 bg-gradient-to-r from-red-200 via-orange-100 to-yellow-50 text-center md:text-left">
            {/* Heading */}
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-4 text-center">
                Inside the Heart of Relentless Dedication: <br /> <span className="text-orange-500"> Fostering Trust and Collaboration</span>
            </h2>


            {/* Text */}
            <p className="text-sm sm:text-base md:text-lg text-gray-600 text-center">
                Gamob's journey epitomizes a relentless pursuit of excellence and a dedication to empowering developers and publishers worldwide.            </p>
        </div>
    );
};

export default Dedication;
