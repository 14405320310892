// import React from "react";
// import { FaLinkedin, FaTwitter, FaFacebook } from "react-icons/fa"; // Import the icons

// const Footer = () => {
//   return (
//     <div className="bg-gradient-to-r from-red-100 via-white-100 to-yellow-50 py-12 px-6">
//       <div className="max-w-screen-xl mx-auto text-gray-800">
//         {/* Contact Information Section */}
//         <div>
           
//         </div>

//         {/* Follow Us Section with Icons */}
//         <div className="text-left mb-8">
//           <h4 className="text-xl font-semibold text-gray-800 mb-4">Follow Us</h4>
//           <div className="flex justify-left space-x-6">
//             <a
//               href="https://www.linkedin.com/company/a4g"
//               className="text-blue-600 hover:text-blue-800"
//             >
//               <FaLinkedin className="text-2xl" /> {/* LinkedIn Icon */}
//             </a>
//             <a
//               href="https://twitter.com/a4g"
//               className="text-blue-600 hover:text-blue-800"
//             >
//               <FaTwitter className="text-2xl" /> {/* Twitter Icon */}
//             </a>
//             <a
//               href="https://facebook.com/a4g"
//               className="text-blue-600 hover:text-blue-800"
//             >
//               <FaFacebook className="text-2xl" /> {/* Facebook Icon */}
//             </a>
//           </div>
//         </div>

//         {/* Notable Mentions Section */}
//         {/* <div className="text-center mb-8">
//           <h4 className="text-xl font-semibold text-gray-800 mb-4">Notable Mentions</h4>
//           <p>Google Certified Publishing Partner badge</p>
//         </div> */}

//         {/* Footer Legal Section */}
//         {/* <div className="text-center text-gray-600">
//           <p>&copy; 2024 A4G. All Rights Reserved.</p>
//           <div className="mt-4">
//             <a href="/terms" className="text-blue-600 hover:text-blue-800">Mediation Setup Terms & Conditions</a> | 
//             <a href="/privacy-policy" className="text-blue-600 hover:text-blue-800">Privacy Policy</a> | 
//             <a href="/cookie-policy" className="text-blue-600 hover:text-blue-800">Cookie Policy</a>
//           </div>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default Footer;

import React from "react";
import { FaLinkedin, FaTwitter, FaFacebook } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <div
      style={{
        background: "linear-gradient(to right, #fed7d7, #fffaf0, #fefcbf)",
        padding: "3rem 1.5rem",
      }}
    >
      <div
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          color: "#4A5568",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "2rem", // Consistent spacing for all columns
        }}
      >
        {/* Column 1: Contact Us + Follow Us */}
        <div style={{ flex: "1", minWidth: "200px" }}>
          <h4
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              marginBottom: "1rem",
            }}
          >
            Contact Us
          </h4>
          {/* <p style={{ marginBottom: "0.5rem" }}>
            <strong>Head Office:</strong> 531A Upper Cross Street, #04-95, Hong
            Lim Complex, Singapore 051531
          </p> */}
          <p style={{ marginBottom: "0.5rem" }}>
            <strong>Email:</strong> info@gamob.com
          </p>
          <p style={{ marginBottom: "0.5rem" }}>
            <strong>Phone:</strong> (+84) 8395-9799
          </p>

          <h4
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              marginTop: "2rem",
              marginBottom: "1rem",
            }}
          >
            Follow Us
          </h4>
          <div style={{ display: "flex", gap: "1.5rem" }}>
            <a
              href="https://www.linkedin.com/company/a4g"
              style={{ color: "#3182CE", fontSize: "1.5rem" }}

            >
              <FaLinkedin />
            </a>
            <a
              href="https://twitter.com/a4g"
              style={{ color: "#3182CE", fontSize: "1.5rem" }}
            >
              <FaTwitter />
            </a>
            <a
              href="https://facebook.com/a4g"
              style={{ color: "#3182CE", fontSize: "1.5rem" }}
            >
              <FaFacebook />
            </a>
          </div>
        </div>

        {/* Column 2 */}
        <div
          style={{
            flex: "1",
            minWidth: "200px",
            marginLeft: "3rem", // Add left margin to the second column
          }}
        >
          <h4
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              marginBottom: "1rem",
            }}
          >
            About Us
          </h4>
          <ul style={{ listStyleType: "none", padding: "0" }}>
            <li style={{ marginBottom: "0.5rem" }}>Our Story</li>
            <li style={{ marginBottom: "0.5rem" }}>Our Mission</li>
          </ul>
        </div>

        {/* Column 3 */}
        <div style={{ flex: "1", minWidth: "200px" }}>
          <h4
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              marginBottom: "1rem",
            }}
          >
            Services
          </h4>
          <ul style={{ listStyleType: "none", padding: "0" }}>
            <li style={{ marginBottom: "0.5rem" }}>Ad Campaigns</li>
            <li style={{ marginBottom: "0.5rem" }}>Consulting</li>
          </ul>
        </div>

        {/* Column 4 */}
        <div style={{ flex: "1", minWidth: "200px" }}>
          <h4
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              marginBottom: "1rem",
            }}
          >
            Support
          </h4>
          <ul style={{ listStyleType: "none", padding: "0" }}>
            <li style={{ marginBottom: "0.5rem" }}>FAQs</li>
            <li style={{ marginBottom: "0.5rem" }}>Contact Support</li>
          </ul>
        </div>

        {/* Column 5 */}
        <div style={{ flex: "1", minWidth: "200px" }}>
          <h4
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              marginBottom: "1rem",
            }}
          >
            Legal
          </h4>
          <ul style={{ listStyleType: "none", padding: "0" }}>
            <li style={{ marginBottom: "0.5rem" }}>Privacy Policy</li>
            <li style={{ marginBottom: "0.5rem" }}>Terms of Service</li>
          </ul>
        </div>
        
      </div>

      {/* Footer Legal Section */}
      <div
        style={{
          textAlign: "center",
          color: "#718096",
          marginTop: "2rem",
        }}
      >
        <p>&copy; 2024 A4G. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;

