import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";

// Lazy load components
const Home = lazy(() => import("./Pages/Home"));
const ContactForm = lazy(() => import("./Pages/ContactUs"));
const RequestProposal = lazy(() => import("./Pages/Publishers"));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Define route for the Home component */}
          <Route path="/" element={<Home />} />
          
          {/* Define route for the Contact Form component */}
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/publishers" element={<RequestProposal />} />
          <Route path="/about" element={<AboutUs />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
