import CoreComponent from "../Components/CoreComponent/CoreComponent";
import Dedication from "../Components/Dedication/Dedication";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import { Suspense } from "react";
import TeamUp from "../Components/TeamUp/TeamUp";
const AboutUs = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
        <Dedication/>
        <CoreComponent/>
        <TeamUp/>
        <Footer />

      </Suspense>
    );
  };
  export default AboutUs;